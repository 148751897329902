<template>
  <v-row
    no-gutters
    class="gestion_comptabilite__dashboard-layout p-4"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="licences-adherents-content">
          <v-row style="height: 100%;">
            <v-col cols="6">
              <dashboardCard
                title_card="Recherche flux financiers"
                auth
                :custom_link="linkFinancialFluxes"
                target="_self"
              />
            </v-col>

            <v-col cols="6">
              <dashboardCard
                title_card="Gestion des factures"
                module_name="gestion_comptabilite__gestion_des_factures"
                isComingSoon
                auth
              />
            </v-col>

            <v-col cols="6" v-if="canAccess">
              <dashboardCard
                title_card="Sage"
                module_name="gestion_comptabilite__gestion_sage"
              />
            </v-col>

            <v-col cols="6">
              <dashboardCard
                title_card="Tableau de contrôle"
                module_name="gestion_comptabilite__tableau_de_controle"
                isComingSoon
                auth
              />
            </v-col>

            <!-- acces kyc masquer -->
            <v-col cols="6" v-if="!showMoneyOutsDashboard">
              <dashboardCard
                title_card="VÉRIFICATION KYC"
                module_name="gestion_lemonway_verification_kyc"
                auth
              />
            </v-col>

            <v-col cols="6" v-else>
              <dashboardCard
                title_card="PLACE DE MARCHÉ"
                module_name="gestion_lemonway_place_marche_kyc"
                auth
              />
            </v-col>
            <!-- a remettre apres -->

            <!-- <v-col cols="6">
              <dashboardCard
                title_card="VERIFICATION KYC"
                module_name="gestion_lemonway_kyc_creation_compte"
                :blockedAccount="blockedAccount"
              />
            </v-col> -->
            <!-- <v-col cols="6" v-if="currentStructureId === 1318">
              <dashboardCard
                title_card="RIB"
                module_name="gestion_comptabilite__structure_rib"
              /> -->
            <v-col cols="6">
              <dashboardCard
                title_card="Récapitulatif mensuel"
                subtitle_card="Les récapitulatifs des dépenses liées aux frais administratifs, licences et cotisations CT et ligues sont consultables sur l'intranet fédéral, prochainement disponible sur MyFFME"
                module_name="gestion_comptabilite__structure_recapitulatif"
                :custom_link="'https://www.montagne-escalade.com/site/BO/GU_structureFinance.php?id=' + currentStructureId"
                auth
              />
            </v-col>
            <!-- <v-col cols="6">
              <dashboardCard
                title_card="KYC"
                module_name="gestion_comptabilite__tableau_de_controle"
              />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DashboardCard from '@/components/Common/DashboardCard';
export default {
  name: 'GestionComptabilite__Dashboard',

  components: { DashboardCard },

  data: () => ({
    loading: true,
    // haveAccount: null,
    showMoneyOutsDashboard: false,
    isFFME: false,
  }),
  async created() {
    await this.setAppTitle({ title: 'Gestion comptabilité' });
    await this.checkStatus();
    this.loading = false;
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('user', ['can']),
    ...mapGetters('structure', ['currentStructure', 'currentStructureId']),

    blockedAccount() {
      return this.currentStructure?.STR_Structure?.EST_CompteBloque;
    },

    canAccess() {
      return this.can('gestion_comptabilite__gestion_sage');
    },

    linkFinancialFluxes() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-finances/flux-financiers?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
  },

  methods: {
    ...mapActions('app', ['setAppTitle']),
    ...mapActions('utilisateurs', ['getUserPaymentAccount']),
    async initialize() {
      await this.setAppTitle({ title: 'Gestion comptabilité' });
      await this.setData();
    },

    async setData() {

    },

    async checkStatus() {
      this.status = [];
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/${this.currentStructureId}`,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );

        const json = await response.json();
        this.showMoneyOutsDashboard = json?.account?.status === 6;
      } catch (error) {
        console.log('erro r:', error);
      }
    },
  },
};
</script>

<style lang="scss">
.gestion_comptabilite__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
